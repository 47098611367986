<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12>
        <v-card outlined class="primary--border">
          <v-card-title class="title">
            <v-icon style="margin-right: 5px; margin-top: -5px">vpn_key</v-icon>
            Authentication Enabler
            <v-spacer></v-spacer>
            <add-button
              @action="form.dialog = true"
              permission="grade-create"
              icon="vpn_key"
              >Allow Authentication</add-button
            >
          </v-card-title>

          <v-card outlined>
            <v-container>
              <alert-message
                :extraSmall="true"
                :title="`Make sure authentication blockage is enabled.`"
                type="primary"
              >
                <template v-slot:message>
                  Before you enable blocked authentication please make sure that
                  you have enabled your authentication blockage rule at
                  `Settings`
                  <a
                    href=""
                    @click.prevent="$router.push('settings')"
                    style="display: block"
                    ><u>Create Blockage Rule Now</u></a
                  >
                </template>
              </alert-message>
            </v-container>
          </v-card>

          <div class="data-represent">
            <div>
              <span class="d-primary"></span>&nbsp;
              <strong></strong>
              <small> Allowed Alway</small>
            </div>
            <div>
              <span class="d-success"></span> &nbsp;
              <strong></strong>
              <small>Allowed Till</small>
            </div>
            <div>
              <span class="d-error"></span> &nbsp;
              <strong> </strong>
              <small> Expired</small>
            </div>
          </div>
          <br />
          <v-data-table
            :headers="headers"
            :items="form.items.data"
            :loading="form.loading"
            :options.sync="pagination"
            :server-items-length="form.items.meta.total"
          >
            <template v-slot:item="{ index, item }">
              <tr :key="index">
                <td>{{ ++index }}</td>
                <td class="text-xs-left">
                  <strong>{{ item.user_name }}</strong>

                  <small style="display: block; color: #888"
                    >{{ item.user_details.grade }} /
                    {{ item.user_details.section }} /
                    {{ item.user_details.grade }} /
                    {{ item.user_details.enroll_code }} /
                    {{ item.user_details.contact }}</small
                  >
                </td>
                <td class="text-xs-left">
                  <v-chip
                    v-if="item.extend_till"
                    small
                    label
                    color="success"
                    text-color="white"
                  >
                    {{ item.extend_till }} ({{ item.entend_till_human }})
                  </v-chip>
                  <v-chip v-else small label color="primary" text-color="white">
                    Always
                  </v-chip>

                  <!-- <small
                    v-if="item.extend_till"
                    style="display:block;color:#888;"
                    >{{ item.entend_till_human }}</small
                  > -->

                  <!-- &nbsp;
                  <v-chip small label color="orange" text-color="white">
                    {{ item.submission_date }}
                  </v-chip> -->
                </td>
                <td class="text-xs-left">
                  <small>{{ item.note }}</small>
                </td>
                <td class="text-xs-left">
                  <v-chip
                    :color="item.block_type === 'LOGIN' ? 'primary' : 'amber'"
                    text-color="white"
                    small
                    label
                  >
                    {{ item.block_type.charAt(0).toUpperCase()
                    }}{{ item.block_type.toLowerCase().slice(1) }}
                  </v-chip>
                </td>
                <td class="text-right">
                  <!-- <edit-button
                    permission="grade-update"
                    @agree="form.edit(item)"
                  /> -->
                  <delete-button
                    permission="grade-delete"
                    @agree="form.delete(item.id)"
                  />
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
      <v-dialog v-model="form.dialog" persistent max-width="550px">
        <v-card>
          <v-card-title class="primary white--text">
            <span class="title">Allow Authentication.</span>
          </v-card-title>
          <v-card-text class="pb-1">
            <v-form
              ref="smsForm"
              @submit.prevent="sendSMS"
              @keydown.native="form.errors.clear($event.target.name)"
              @keyup.enter="sendSMS"
              lazy-validation
            >
              <v-layout row wrap pt-2>
                <v-flex xs12>
                  <br />
                  <v-student-search-field
                    :withDeleted="false"
                    @onSelectStudent="selectStudent"
                  ></v-student-search-field>
                </v-flex>

                <v-flex xs12>
                  <br />
                  <v-calendar-field
                    :show-converted="showconverted"
                    :clearable="true"
                    :disable-previous-date="true"
                    id-val="date"
                    v-model="form.extend_till"
                    label="Select Extend Allow Till (Leave if you want to allow always)"
                    @input="tooglConvertedDate()"
                  >
                  </v-calendar-field>
                </v-flex>
                <v-flex xs12>
                  <br />
                  <v-select
                    autocomplete="off"
                    :items="block_type"
                    label="Allowed For"
                    outlined
                    dense
                    v-model="form.block_type"
                  ></v-select>
                </v-flex>
                <v-flex xs12>
                  <v-divider />
                </v-flex>
                <br />
                <v-flex xs12>
                  <v-textarea
                    class="pa-0"
                    outlined
                    placeholder="Add description here (optional)"
                    dense
                    v-model="form.note"
                    label="...."
                    :rows="3"
                  />
                </v-flex>
              </v-layout>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="warning" text @click="(form.dialog = false), reset()"
              >Close</v-btn
            >
            <v-btn
              color="success"
              text
              @click="allowAuth"
              :disabled="!form.user_id"
              >Allow
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import Form from "@/library/Form";
import Mixins from "@/library/Mixins";

export default {
  mixins: [Mixins],
  data: () => ({
    form: new Form(
      {
        user_id: "",
        user_name: "",
        extend_till: "",
        note: "",
        user_details: {},
        block_type: "",
      },
      "/api/auth-allowed-users"
    ),
    block_type: [
      { value: "LOGIN", text: "Login" },
      { value: "EXAM", text: "Exam" },
    ],
    showconverted: true,
    authEnableModal: false,

    headers: [
      { text: "#", align: "left", sortable: false, value: "id", width: "20px" },
      {
        text: "Name",
        value: "user_name",
        align: "left",
        sortable: false,
        width: 300,
      },
      {
        text: "Extended Till",
        value: "extend_till",
        align: "left",
        sortable: false,
        width: 200,
      },
      { text: "Note", value: "note", align: "left", sortable: false },
      {
        text: "Block Type",
        value: "block_type",
        align: "left",
        sortable: false,
      },
      { text: "Action", value: "action", align: "right", sortable: false },
    ],
  }),

  computed: {
    ...mapState(["batch"]),
    items() {
      return this.entries.map((entry) => {
        return { Name: entry.name, id: entry.id };
      });
    },
  },

  watch: {
    pagination: function() {
      this.get();
    },
  },

  methods: {
    tooglConvertedDate() {
      this.showconverted = true;
    },
    get() {
      let query = this.queryString();
      this.form.get(null, query).then(({ data }) => {});
    },
    allowAuth() {
      this.form.store().then(() => {
        this.reset();
      });
    },
    selectStudent(student) {
      this.form.user_id = student.id;
      this.form.user_name = student.name;
      this.form.user_details = {
        enroll_code: student.enroll_code,
        enroll_id: student.enroll_id,
        grade: student.details.grade,
        section: student.details.section,
        contact: student.details.guardian.mobile,
      };
    },
    reset() {
      this.$refs.smsForm.reset();
      this.showconverted = false;
    },
  },
  mounted() {},
};
</script>
<style lang="scss"></style>
